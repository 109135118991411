<template>
  <div :style='`width:${width}; height:${height}`' v-loading="loading" :class="CSSClasses">
    <vue-apex-charts
      :options="options"
      :type="typeChart"
      :series="series"
      :data-type="editorChart.type"
      :data-source-id="sourceId"
      :data-alias="editorAlias || null"
      ref="chart"
      height="100%"
      width="100%"
    >
    </vue-apex-charts>
    <slot></slot>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import mixin from '../mixins'
import registryMixin from '../registry/registry_mixins'
import VisibilityMixin from '@/mixins/visibility'
import { getCounterFilters } from '@/components/InterfaceEditor/components/basic/HelpersForBasicCmp/index.js'
import FilterBuilder, { EComponentTypes } from '../utils'

export default {
  name: 'a-chart',
  components: {
    VueApexCharts
  },
  inject: {
    getRegistryRecordId: { default: () => {} },
    forceUpdateSettingsPanel: { default: () => () => {} },
    getComponents: {
      default: () => () => []
    }
  },
  mixins: [mixin, registryMixin, VisibilityMixin],
  props: {
    name: {
      frozen: true
    },
    size: {
      frozen: true
    },
    align: {
      frozen: true
    },
    margin: {
      frozen: true
    },
    readonly: {
      frozen: true
    },
    hiddenCondition: {
      frozen: true
    },
    block: {
      frozen: true
    },
    isRequired: {
      frozen: true
    },
    wrapper: {
      frozen: true
    },
    alwaysActive: {
      frozen: true
    },
    editorAlias: {
      type: String,
      description: 'alias'
    },
    editorChart: {
      type: Object,
      editor: 'Chart',
      default: () => {
        return {
          type: 'plugin',
          colors: ['#69B3E7', '#3F95DA', '#0977CB', '#0058B9', '#0039A3', '#001489']
        }
      },
      options: { type: 'column' }
    },
    params: {
      type: String,
      description: 'add_parameters'
    },
    multiYaxis: {
      type: Boolean,
      description: 'multy_y_axis',
      default: true
    },
    minimalYvalue: {
      type: Number,
      description: 'minimum_value_series',
      default: 0
    },
    maximalYvalue: {
      type: Number,
      description: 'maximum_value_series',
      default: 100
    },
    tickAmount: {
      type: Number,
      description: 'interval',
      default: 5,
      options: {
        tooltip: {
          content: 'number_horizontal_lines',
          show: true
        }
      }
    },
    forceNiceScale: {
      type: Boolean,
      description: 'automatic_scale',
      options: {
        tooltip: {
          content: 'min_max_ignored',
          show: true
        }
      },
      default: true
    },
    width: {
      type: String,
      description: 'width',
      default: '100%'
    },
    height: {
      type: String,
      description: 'height',
      default: '100%'
    },
    filters: {
      type: Array,
      editor: 'Filters',
      options: {
        showXrefOption: true,
        showEqualsTypes: true
      }
    }
  },
  data () {
    return {
      countFilters: 0,
      isLoadData: true,
      isInitCmpDone: false,
      loaderInstance: undefined,
      loading: false,
      options: {
        title: {},
        colors: undefined,
        yaxis: {
          // min: Number.isInteger(this.minimalYvalue) ? this.minimalYvalue : undefined,
          // forceNiceScale: true,
          labels: {
            formatter: function (value) {
              if (!isFinite(value)) return ''
              return (value ?? '').toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
              // return new Intl.NumberFormat('ru-RU').format(value)
            }
          }
        },
        chart: {
          stacked: false,
          animations: {
            enabled: false
          },
          toolbar: {
            show: true,
            tools: {
              download: true,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false
            }
          }
        },
        dataLabels: {
          enabled: false
        },
        fill: {
          opacity: 1
        },
        stroke: {
          width: 2,
          curve: 'smooth'
        },
        xaxis: {
          categories: [],
          tickPlacement: 'between'
        }
      },
      series: []
    }
  },
  computed: {
    dataFilters () {
      if (this.editorChart.type === 'plugin') {
        let filters = []
        if (this.filters) {
          this.filters.forEach((item) => {
            if (!item.type || item.type === 'field') {
              if (this.getModel()[item.attribute] && item.alias) {
                filters.push(`${item.alias}=${this.getModel()[item.attribute]}`)
              }
            } else if (item.type === 'constant' && item.alias) {
              filters.push(`${item.alias}=${item.attribute}`)
            }
          })
        }
        return filters
      } else {
        const builder = new FilterBuilder(
          this.filters,
          this.getModel(),
          this.$store,
          EComponentTypes.chart
        )

        const filters = builder.buildAsApiQl()
        if (filters.length === 0 && this.isEditor()) {
          return {
            limit: 10
          }
        }
        if (filters.length > 0 && this.isEditor()) {
          return {
            where: {
              and: [...filters]
            },
            limit: 20
          }
        }
        if (filters.length > 0) {
          return {
            where: {
              and: [...filters]
            }
          }
        }

        return {}
      }
    },
    typeChart () {
      if (this.editorChart.series?.length && this.editorChart.series.every(item => item?.type === 'line')) {
        return ''
      }
      return 'bar'
    },
    sourceId () {
      return this.editorChart?.extendObject || this.editorChart?.requestsId || null
    }
  },
  watch: {
    dataFilters: {
      handler (val) {
        // первое условие актуально при первой загрузке
        if (!this.isLoadData && !this.isInitCmpDone) {
          if (this.countFilters == val?.where?.and.length) {
            this.loadData()
            this.isInitCmpDone = true
          }
        } else if (this.isInitCmpDone) {
          this.loadData()
        }
      }
    },
    editorChart: {
      handler (value) {
        this.options.title = Object.assign({}, this.options.title, value.title)
        this.options.legend = Object.assign({}, this.options.legend, value.legend)
        this.options.colors = value.colors
      },
      immediate: true
    },
    editorAlias () {
      this.forceUpdateSettingsPanel()
    }
  },
  created () {
    this.loading = true
    let optimizetionTick = this.tickAmount > 20 ? 20 : this.tickAmount
    let options = {
      forceNiceScale: this.forceNiceScale,
      tickAmount: optimizetionTick
    }
    if (!this.forceNiceScale) {
      options = {
        ...options,
        min: this.minimalYvalue,
        max: this.maximalYvalue
      }
    }
    this.options.yaxis = Object.assign({}, this.options.yaxis, options)

    this.countFilters = getCounterFilters(this.getComponents, this.filters)
    // Если фильтров нет, то при иницилизации компонента грузим данные
    if (!this.countFilters) {
      this.isLoadData = true
      // console.log('грузим данные')
    } else {
      this.isLoadData = false
      // console.log('будет watch')
    }
  },
  async mounted () {
    if ((this.editorChart.type === 'plugin' && !this.editorChart.plugin) ||
            (this.editorChart.type === 'extended_object' && !this.editorChart.extendObject) ||
            (this.editorChart.type === 'requests' && !this.editorChart.requestsId)
    ) {
      return false
    }
    this.onVisibilityChange(() => {
      this.$nextTick(() => {
        if (this.$refs.chart && this.$refs.chart.chart && this.$refs.chart.$el.children[0].offsetHeight === 0) {
          this.$refs.chart.chart.update()
        }
      })
    })
    if (this.isLoadData) {
      await this.loadData()
      this.isLoadData = false
      this.isInitCmpDone = true
    }
    // предохранитель
    setTimeout(() => {
      if (!this.series.length && !this.isLoadData) {
        console.warn('timeout')
        this.loadData()
        this.isInitCmpDone = true
      }
    }, 5000)
  },
  methods: {
    async loadData () {
      // if (!this.loaderInstance) {
      // this.loaderInstance = this.$loading({ target: this.$el })
      // }
      this.loading = true
      if (this.editorChart.type === 'plugin') {
        let data = await this.$http.get(`${this.$config.api}/registryservice/plugins/execute/${this.editorChart.plugin}?recordId=${this.getRegistryRecordId() || 0}&${this.dataFilters.join('&')}&${this.params || ''}`)
          .finally(() => {
            // this.loaderInstance && this.loaderInstance.close()
            // this.loaderInstance = undefined
            this.loading = false
          })
        if (data.data) {
          if (this.multiYaxis) {
            this.setYaxis(data.data.series)
          }
          this.$refs.chart.updateOptions({
            xaxis: {
              categories: data.data.categories
            }
          })
          this.series = data.data.series
        }
      } else if (this.editorChart.type === 'extend_object') {
        let data = await this.$http
          .post(`${this.$config.api}/datawarehouseservice/extended_object/${this.editorChart.extendObject}`, this.dataFilters, { hideNotification: true })
          .finally(() => {
            // this.loaderInstance && this.loaderInstance.close()
            // this.loaderInstance = undefined
            this.loading = false
          })
        let series = this.getExtendedObjectSeries(data.data)
        if (this.multiYaxis) {
          this.setYaxis(series)
        }
        this.$refs.chart.updateOptions({
          xaxis: {
            categories: this.getExtendedObjectCategories(data.data)
          }
        })
        this.series = series
      } else if (this.editorChart.type === 'requests' && this.editorChart.requestsId) {
        const data = await this.$http
          .post(`${this.$config.api}/datawarehouseservice/query/${this.editorChart.requestsId}`, this.dataFilters, { hideNotification: true })
          .finally(() => {
            // this.loaderInstance && this.loaderInstance.close()
            // this.loaderInstance = undefined
            this.loading = false
          })
        let series = this.getExtendedObjectSeries(data.data)
        if (this.multiYaxis) {
          this.setYaxis(series)
        }
        this.$refs.chart.updateOptions({
          xaxis: {
            categories: this.getRequestsCategories(data.data)
          }
        })
        this.series = series
      } else {
        // this.loaderInstance && this.loaderInstance.close()
        // this.loaderInstance = undefined
        this.loading = false
      }
    },
    getExtendedObjectSeries (data) {
      // Для построении графиков ввиде линии, когда серия одна
      // TODO рефактор
      if (this.editorChart.series.length === 1) {
        if (this.editorChart.series[0].type === 'line') {
          let series = [{
            name: this.editorChart.series[0].name,
            data: []
          }
          ]
          // данные из настройки серии -> Поле
          let filed = this.editorChart.series[0].field?.name || this.editorChart.series[0].fieldRequest
          let arrayData = data.reduce((acc, item) => {
            acc.push(item[filed])
            return acc
          }, [])
          series[0].data.push(...arrayData)

          return series
        }
      }
      let colors = []
      let opacity = []
      let answer = []
      this.editorChart.series.forEach((item) => {
        if (item.category.id) {
          let categories = {}
          data.forEach((row) => {
            if (typeof categories[row[item.category.name]] === 'undefined') {
              categories[row[item.category.name]] = []
            }
            categories[row[item.category.name]].push(row[item.field.name])
          })
          for (let category in categories) {
            if (categories.hasOwnProperty(category)) {
              answer.push({
                name: `${item.name} (${category})`,
                type: item.type,
                data: categories[category]
              })
            }
          }
          colors.push(...(item.category.color || []))
        } else {
          let object = {}
          object.name = item.name
          object.type = item.type
          object.data = data.map(row => row[item.field.name || item.fieldRequest])
          colors.push(item.color)
          answer.push(object)
        }
        opacity.push(item.opacity)
      })
      this.$refs.chart.updateOptions({
        colors: colors
      })
      this.$set(this.options, 'colors', colors)
      this.$refs.chart.updateOptions({
        fill: {
          opacity: [...opacity]
        }
      })
      return answer
    },
    getExtendedObjectCategories (data) {
      return data.map(item => item[this.editorChart.categorie.name] + '').filter((value, index, self) => self.indexOf(value) === index)
    },
    getRequestsCategories (data) {
      return data.map(item => item[this.editorChart.categorieRequests] + '').filter((value, index, self) => self.indexOf(value) === index)
    },
    setYaxis (data) {
      if (!data) {
        return false
      }
      let types = {
        line: { name: (data.find(item => item.type === 'line') || {}).name, isset: false, opposite: false },
        column: { name: (data.find(item => item.type === 'column') || {}).name, isset: false, opposite: false },
        area: { name: (data.find(item => item.type === 'area') || {}).name, isset: false, opposite: false }
      }
      if (!types.line.name && !types.column.name) {
        return false
      }
      let series = []
      data.forEach((item) => {
        let element = {}
        if (Number.isInteger(this.minimalYvalue)) {
          element.min = this.minimalYvalue
        }
        element.seriesName = types[item.type].name
        element.opposite = types[item.type].opposite
        if (types[item.type].isset) {
          element.show = false
        } else {
          types[item.type].isset = true
        }
        series.push(element)
      })
      this.$set(this.options, 'yaxis', series)
    }
  }
}
</script>

<style scoped>

</style>
